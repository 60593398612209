import { Link } from "react-router-dom";
export const convertPaidByText = (value) => {
  if (value.toLowerCase() === 'sender') {
    return 'ผู้ส่ง'
  } else {
    return 'ผู้รับ'
  }
}

export const checkImageFlowOld = (image) => {
  if (image === undefined) return ""

  return image.toString().indexOf('.com') === -1
}

export const getMethodText = (transaction) => {
  if (transaction.method === '') {
    if (transaction.type === 3)
      // return <Link to={`/order/detail/${transaction.code}`}>{transaction.code}</Link>
      return transaction.code
    if (transaction.type === 1 || transaction.type === 2)
      return `${transaction.bank_name_th}(${transaction.bank_short_name})`
  } else if (transaction.method.toLowerCase() === 'full') {
    return 'เครดิต/เดบิตการ์ด'
  } else if (transaction.method.toLowerCase() === 'promptpay') {
    return 'PromptPay'
  } else if (transaction.method.toLowerCase() === 'promotion_code') {
    return `โปรโมชัน ${transaction.code}`
  }

  return transaction.method
}

export const getRoleNameText = (role) => {
  if (role === 'user_business') {
    return 'ผู้ใช้งานธุรกิจ'
  } else if (role === 'user') {
    return 'ผู้ใช้งานทั่วไป'
  }

  return role.toString()
}