import "react-day-picker/lib/style.css";
import "moment/locale/th";

import { APIProvider, Map } from '@vis.gl/react-google-maps';
import React, { useEffect, useState } from "react";
import { api, google_api_key, header_token, maps_id, socket_api } from "../../config";

import Axios from "axios";
import { CustomMarker } from './CustomMarker'
import { FilterCard } from './FilterCard'
import moment from "moment";

export const Maps = () => {
  const [drivers, setDrivers] = useState([])
  // const [driversWaiting, setDriversWating] = useState([])
  const [newOrder, setNewOrder] = useState([])
  // 2 => online
  const [riderStatusFilter, setRiderStatusFilter] = useState(2)
  // 0 => all
  const [hasOrderFilter, setHasOrderFilter] = useState(0)
  const [carServiceFilter, setCarServiceFilter] = useState(0)
  const [riders, setRiders] = useState([])

  const ONE_SECCOUND = 1000;

  const getAllRider = async () => {
    try {
      let send_data = {
        area_id: "",
        employee_type_id: "",
        search: "",
        status: -1,
        team_id: "",
        role: ["rider"],
      };

      let { data } = await Axios.post(
        `${api}/admin/user/filter?page=${1}&limit=${999999999}`,
        send_data,
        header_token
      );
      setRiders(data.users || [])
    } catch (error) {
      console.log('getAllRider error: ', error)
      setRiders([])
    }
  }

  const initSocket = () => {
    let oldTime = null
    let driversWaiting = []
    // console.log('token: ', localStorage.getItem("token"))
    const socket = new WebSocket(socket_api + '?token=' + localStorage.getItem("token"));
    // Connection opened
    socket.addEventListener("open", (event) => {
      // console.log('open socket')
      socket.send('{}')
      // console.log('send msg done')
    })

    // Listen for messages
    socket.addEventListener("message", (event) => {
      const data = JSON.parse(event.data)
      if (data['action'] === undefined) {
        const date = new Date();
        if (oldTime === null) {
          oldTime = date
        } else {
          if ((date - oldTime) >= ONE_SECCOUND) {
            oldTime = null
            setDrivers([...driversWaiting])
          }
        }
        if (data.role.name_en === 'user' || data.status !== 1 || (data.latitude === 0 && data.longitude === 0)) {
          driversWaiting = [...driversWaiting]
        } else {
          const dup = driversWaiting.findIndex((val) => val.user_id === data.user_id);
          if (dup > -1) {
            driversWaiting[dup].latitude = data.latitude
            driversWaiting[dup].longitude = data.longitude
            driversWaiting = [...driversWaiting]
          } else {
            driversWaiting = [...driversWaiting, data]
          }
        }
      } else {
        // console.log('action: => ', data["action"])
      }
    })

    return () => {
      socket.close()
    };
  }

  const getNewOrder = async () => {
    try {
      let start = moment(new Date(), "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(new Date(), "DD/MM/YYYY").format("YYYY-MM-DD");

      let send_data = {
        start_date: start,
        end_date: end,
        search: "",
        area_id: "",
        service: ["car"],
        status: [1],
      };

      let { data } = await Axios.post(
        `${api}/admin/order/filter?page=${1}&limit=${'99999999'}&sort=${-1}&sort_by=${"created_at"}`,
        send_data,
        header_token
      );

      setNewOrder(data.orders || [])
    } catch (error) {
      console.log('getNewOrder: ', error)
    }
  }

  useEffect(() => {
    initSocket()
    getAllRider()
    getNewOrder()
    const intervalId = setInterval(() => {
      getNewOrder()
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [])

  const defaultLocation = { lat: 13.75398, lng: 100.50144 }

  const onChangeStatus = (e) => {
    // { value: 2, label: "ออนไลน์" },
    // { value: 1, label: "ออฟไลน์" },
    // { value: 0, label: "ทั้งหมด" },
    setRiderStatusFilter(e.value)
  }

  const onChangeHasOrder = (e) => {
    // { value: 2, label: "รับงานอยู่" },
    // { value: 1, label: "รอรับงาน" },
    // { value: 0, label: "ทั้งหมด" },
    setHasOrderFilter(e.value)
  }

  const onChangeCarService = (e) => {
    setCarServiceFilter(e.value)
  }

  const getFilterData = () => {
    const filters = []
    for (let index = 0; index < drivers.length; index++) {
      const driver = drivers[index];

      if (
        (driver.latitude === 0 && driver.longitude === 0)
        || (driver.latitude === undefined && driver.longitude === undefined)
      ) continue
      // filter status
      if (riderStatusFilter === 2 && !driver.available) {
        continue
      } else if (riderStatusFilter === 1 && driver.available) {
        continue
      }
      // filter has order
      if (hasOrderFilter === 2 && !driver.has_order) {
        continue
      } else if (hasOrderFilter === 1 && driver.has_order) {
        continue
      }
      // filter car service
      if (carServiceFilter !== 0) {
        if (carServiceFilter !== driver.vehicle_type.id) {
          continue
        }
      }
      const driverId = driver.id.substring(10, driver.id.length - 2)
      const data = riders.find((v) => v.id === driverId)
      filters.push({ ...driver, phone_number: data !== undefined ? data.phone_number : '-', id: driverId })
    }

    return filters
  }

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap pb-2 mb-2">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold">แผนที่</h4>
          </div>
        </div>
        <FilterCard
          onChangeStatus={(e) => onChangeStatus(e)}
          onChangeHasOrder={(e) => onChangeHasOrder(e)}
          onChangeCarService={(e) => onChangeCarService(e)}
          riders={getFilterData()}
          onClickValueSearch={(id) => {
            const ele = document.getElementById(`marker-rider-${id}`)
            ele.click()
          }}
        />
      </div>
      <div
        className="card-body"
        style={{
          height: `${100 * 0.73}vh`,
          width: '100%',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: '#867F85',
          padding: 0,
        }}
      >
        <APIProvider apiKey={google_api_key}>
          <Map
            style={{
              width: '100%',
              height: '100%',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: '#867F85',
            }}
            defaultCenter={defaultLocation}
            defaultZoom={12}
            gestureHandling={'greedy'}
            disableDefaultUI={true}
            mapId={maps_id}
          >
            {
              drivers.length > 0 && drivers.map((driver, index) => {
                if (
                  (driver.latitude === 0 && driver.longitude === 0)
                  || (driver.latitude === undefined && driver.longitude === undefined)
                ) return null
                // filter status
                if (riderStatusFilter === 2 && !driver.available) {
                  return null
                } else if (riderStatusFilter === 1 && driver.available) {
                  return null
                }
                // filter has order
                if (hasOrderFilter === 2 && !driver.has_order) {
                  return null
                } else if (hasOrderFilter === 1 && driver.has_order) {
                  return null
                }
                // filter car service
                if (carServiceFilter !== 0) {
                  if (carServiceFilter !== driver.vehicle_type.id) {
                    return null
                  }
                }

                const driverId = driver.id.substring(10, driver.id.length - 2)

                return <CustomMarker
                  id={`custom-marker-rider-${driverId}`}
                  key={`custom-marker-rider-${driverId}`}
                  driver={driver}
                  riders={riders}
                />
              })
            }
            {
              newOrder.length > 0 && newOrder.map((order, index) => {
                return <CustomMarker key={`order-${index}`} driver={null} order={order} />
              })
            }
          </Map>
        </APIProvider>
      </div>
    </div>
  )
}